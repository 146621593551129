<script lang="ts">
	import { cn } from '$lib/utils/utils';

	interface Props {
		class?: string | undefined | null;
		size?: any;
		children?: import('svelte').Snippet;
		[key: string]: any;
	}

	let {
		class: className = undefined,
		size = 'default' as 'default' | 'large',
		children,
		...rest
	}: Props = $props();
</script>

<div
	class={cn(
		'p-10 space-y-4 border shadow-md rounded-xl bg-[#131315] w-80 lg:w-96 border-zinc-800 transition-colors duration-300 relative',
		className
	)}
	{...rest}
	class:large={size === 'large'}
>
	{@render children?.()}
</div>

<style>
	.large {
		width: 420px;
		@media (min-width: 650px) {
			width: 600px;
		}
	}
</style>
